export async function loadAndTransformAudio (urlAudioA, urlAudioB) {
  let audioBufferA = null
  let audioBufferB = null

  // Load audio A with error handling
  try {
    if (urlAudioA) {
      const response = await fetch(urlAudioA, { headers: {} })
      if (!response.ok) throw new Error(`Failed to load audio from ${urlAudioA}`)
      audioBufferA = await response.arrayBuffer()
    }
  } catch (error) {
    console.error(`Error loading audio A: ${error.message}`)
  }

  // Load audio B with error handling
  try {
    if (urlAudioB) {
      const response = await fetch(urlAudioB, { headers: {} })
      if (!response.ok) throw new Error(`Failed to load audio from ${urlAudioB}`)
      audioBufferB = await response.arrayBuffer()
    }
  } catch (error) {
    console.error(`Error loading audio B: ${error.message}`)
  }

  // If neither audio was successfully loaded, return null
  if (!audioBufferA && !audioBufferB) return null

  const audioA = new AudioContext()
  const audioB = new AudioContext()

  let decodedAudioA = audioBufferA ? await audioA.decodeAudioData(audioBufferA) : null
  let decodedAudioB = audioBufferB ? await audioB.decodeAudioData(audioBufferB) : null

  // Check durations and adjust if necessary
  if (decodedAudioA && decodedAudioB && decodedAudioA.duration !== decodedAudioB.duration) {
    const targetDuration = Math.max(decodedAudioA.duration, decodedAudioB.duration)

    if (decodedAudioA.duration < targetDuration) {
      decodedAudioA = adjustBufferDuration(audioA, decodedAudioA, targetDuration)
    }

    if (decodedAudioB.duration < targetDuration) {
      decodedAudioB = adjustBufferDuration(audioB, decodedAudioB, targetDuration)
    }
  }

  // Convert the loaded and adjusted buffers to blobs
  const blobs = {}

  if (decodedAudioA) {
    const wavBufferA = bufferToWave(decodedAudioA, decodedAudioA.length)
    blobs.blobA = new Blob([new DataView(wavBufferA)], { type: 'audio/wav' })
  }

  if (decodedAudioB) {
    const wavBufferB = bufferToWave(decodedAudioB, decodedAudioB.length)
    blobs.blobB = new Blob([new DataView(wavBufferB)], { type: 'audio/wav' })
  }

  return blobs
}

function adjustBufferDuration (context, buffer, targetDuration) {
  const numberOfChannels = buffer.numberOfChannels
  const targetLength = Math.floor(targetDuration * buffer.sampleRate)
  const outputBuffer = context.createBuffer(numberOfChannels, targetLength, buffer.sampleRate)

  for (let channel = 0; channel < numberOfChannels; channel++) {
    const inputData = buffer.getChannelData(channel)
    const outputData = outputBuffer.getChannelData(channel)

    for (let i = 0; i < targetLength; i++) {
      outputData[i] = i < inputData.length ? inputData[i] : 0
    }
  }

  return outputBuffer
}

export function bufferToWave (abuffer, len) {
  const numOfChan = abuffer.numberOfChannels
  const length = len * numOfChan * 2 + 44
  const buffer = new ArrayBuffer(length)
  const view = new DataView(buffer)
  const channels = []
  let i
  let sample
  let offset = 0
  let pos = 0

  setUint32(0x46464952) // "RIFF"
  setUint32(length - 8) // File length - 8
  setUint32(0x45564157) // "WAVE"

  setUint32(0x20746d66) // "fmt " chunk
  setUint32(16) // Length = 16
  setUint16(1) // PCM (uncompressed)
  setUint16(numOfChan)
  setUint32(abuffer.sampleRate)
  setUint32(abuffer.sampleRate * 2 * numOfChan) // Avg. bytes/sec
  setUint16(numOfChan * 2) // Block-align
  setUint16(16) // 16-bit (hardcoded in this demo)

  setUint32(0x61746164) // "data" - chunk
  setUint32(length - pos - 4) // Chunk length

  for (i = 0; i < abuffer.numberOfChannels; i++) {
    channels.push(abuffer.getChannelData(i))
  }

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) { // Interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])) // Clamp
      sample = (0.5 + sample * 32767) | 0 // Scale to 16-bit signed int
      view.setInt16(pos, sample, true) // Write 16-bit sample
      pos += 2
    }
    offset++ // Next source sample
  }

  function setUint16 (data) {
    view.setUint16(pos, data, true)
    pos += 2
  }

  function setUint32 (data) {
    view.setUint32(pos, data, true)
    pos += 4
  }

  return buffer
}

export async function loadAudio (context, url) {
  try {
    const response = await fetch(url, { headers: {} })
    if (!response.ok) throw new Error(`Failed to load audio from ${url}`)
    const arrayBuffer = await response.arrayBuffer()
    return await context.decodeAudioData(arrayBuffer)
  } catch (error) {
    console.error(`Error loading audio: ${error.message}`)
    return null
  }
}

export function mergeAudioBuffers (context, buffer1, buffer2) {
  const numberOfChannels = Math.max(buffer1.numberOfChannels, buffer2.numberOfChannels)
  const length = Math.max(buffer1.length, buffer2.length)
  const outputBuffer = context.createBuffer(numberOfChannels, length, buffer1.sampleRate)

  for (let channel = 0; channel < numberOfChannels; channel++) {
    const outputData = outputBuffer.getChannelData(channel)
    const inputData1 = buffer1.getChannelData(channel % buffer1.numberOfChannels)
    const inputData2 = buffer2.getChannelData(channel % buffer2.numberOfChannels)

    for (let i = 0; i < length; i++) {
      const sample1 = i < inputData1.length ? inputData1[i] : 0
      const sample2 = i < inputData2.length ? inputData2[i] : 0
      outputData[i] = sample1 + sample2
    }
  }

  return outputBuffer
}
