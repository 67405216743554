import * as JSZip from 'jszip'
import { bufferToWave, loadAudio, mergeAudioBuffers } from './audioNormalization'

export async function createZipWithMergedAudio ({ zipNamePostfix = '', urlAudioA = null, urlAudioB = null }) {
  const context = new (window.AudioContext || window.webkitAudioContext)()
  const zip = new JSZip()
  const folder = zip.folder(`audio_files_${zipNamePostfix}`)

  let buffer1 = null
  let buffer2 = null

  // Load the audio files with error handling
  if (urlAudioA) {
    buffer1 = await loadAudio(context, urlAudioA)
    if (buffer1) {
      folder.file('audioA.mp3', fetch(urlAudioA, { headers: {} }).then(res => res.blob()))
    }
  }

  if (urlAudioB) {
    buffer2 = await loadAudio(context, urlAudioB)
    if (buffer2) {
      folder.file('audioB.mp3', fetch(urlAudioB, { headers: {} }).then(res => res.blob()))
    }
  }

  // Merge audio files if both are loaded successfully
  if (buffer1 && buffer2) {
    const mergedBuffer = mergeAudioBuffers(context, buffer1, buffer2)
    const wavBuffer = bufferToWave(mergedBuffer, mergedBuffer.length)
    const mergedBlob = new Blob([new DataView(wavBuffer)], { type: 'audio/wav' })

    folder.file('merged.wav', mergedBlob)
  }

  // Generate the zip file and download it if any file was successfully processed
  if (buffer1 || buffer2) {
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(content)
      link.download = `audio_files_${zipNamePostfix}.zip`
      link.click()
    })
  } else {
    console.error('No valid audio files were loaded. Unable to create zip.')
  }
}
