<template>
  <div class="dual-track-player">
    <div class="dual-track-player-block">
      <div class="default-title">
        <slot name="title">
          Records
        </slot>
        <slot name="title-append"></slot>
      </div>

      <div class="waveform-wrapper">
        <div class="waveform-container">
          <template v-if="$props.audioA || $props.audioB">
            <div id="waveform" class="waveform" v-if="$props.audioA"></div>
            <div id="waveform2" class="waveform" v-if="$props.audioB"></div>
          </template>
          <div v-else>No Data</div>
        </div>
      </div>

      <div class="audio-actions">
        <template v-if="showSyncBtn">
          <button class="action-btns half-circle"
                  v-show="!isPlaying1 && !isPlaying2 || ((!isPlaying1 && isPlaying2) || (isPlaying1 && !isPlaying2))"
                  style="position: absolute; left: 0; top: 19px;"
                  @click="toggleSync">
            <svg class="action-btn" v-if="isPlaying1 && isPlaying2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Media / Pause">
                <g id="Vector">
                  <path d="M15 5.5V18.5C15 18.9647 15 19.197 15.0384 19.3902C15.1962 20.1836 15.816 20.8041 16.6094 20.9619C16.8026 21.0003 17.0349 21.0003 17.4996 21.0003C17.9642 21.0003 18.1974 21.0003 18.3906 20.9619C19.184 20.8041 19.8041 20.1836 19.9619 19.3902C20 19.1987 20 18.9687 20 18.5122V5.48777C20 5.03125 20 4.80087 19.9619 4.60938C19.8041 3.81599 19.1836 3.19624 18.3902 3.03843C18.197 3 17.9647 3 17.5 3C17.0353 3 16.8026 3 16.6094 3.03843C15.816 3.19624 15.1962 3.81599 15.0384 4.60938C15 4.80257 15 5.03534 15 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4 5.5V18.5C4 18.9647 4 19.197 4.03843 19.3902C4.19624 20.1836 4.81599 20.8041 5.60938 20.9619C5.80257 21.0003 6.0349 21.0003 6.49956 21.0003C6.96421 21.0003 7.19743 21.0003 7.39062 20.9619C8.18401 20.8041 8.8041 20.1836 8.96191 19.3902C9 19.1987 9 18.9687 9 18.5122V5.48777C9 5.03125 9 4.80087 8.96191 4.60938C8.8041 3.81599 8.18356 3.19624 7.39018 3.03843C7.19698 3 6.96465 3 6.5 3C6.03535 3 5.80257 3 5.60938 3.03843C4.81599 3.19624 4.19624 3.81599 4.03843 4.60938C4 4.80257 4 5.03534 4 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </g>
            </svg>
          </button>

          <button class="action-btns half-circle2"
                  v-show="isPlaying1 && isPlaying2"
                  style="position: absolute; left: 0; top: 19px;"
                  @click="toggleSync">
            <svg class="action-btn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Media / Pause">
                <g id="Vector">
                  <path d="M15 5.5V18.5C15 18.9647 15 19.197 15.0384 19.3902C15.1962 20.1836 15.816 20.8041 16.6094 20.9619C16.8026 21.0003 17.0349 21.0003 17.4996 21.0003C17.9642 21.0003 18.1974 21.0003 18.3906 20.9619C19.184 20.8041 19.8041 20.1836 19.9619 19.3902C20 19.1987 20 18.9687 20 18.5122V5.48777C20 5.03125 20 4.80087 19.9619 4.60938C19.8041 3.81599 19.1836 3.19624 18.3902 3.03843C18.197 3 17.9647 3 17.5 3C17.0353 3 16.8026 3 16.6094 3.03843C15.816 3.19624 15.1962 3.81599 15.0384 4.60938C15 4.80257 15 5.03534 15 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4 5.5V18.5C4 18.9647 4 19.197 4.03843 19.3902C4.19624 20.1836 4.81599 20.8041 5.60938 20.9619C5.80257 21.0003 6.0349 21.0003 6.49956 21.0003C6.96421 21.0003 7.19743 21.0003 7.39062 20.9619C8.18401 20.8041 8.8041 20.1836 8.96191 19.3902C9 19.1987 9 18.9687 9 18.5122V5.48777C9 5.03125 9 4.80087 8.96191 4.60938C8.8041 3.81599 8.18356 3.19624 7.39018 3.03843C7.19698 3 6.96465 3 6.5 3C6.03535 3 5.80257 3 5.60938 3.03843C4.81599 3.19624 4.19624 3.81599 4.03843 4.60938C4 4.80257 4 5.03534 4 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </g>
            </svg>
          </button>
        </template>

        <div class="audio-block audio-block-shift" v-if="showAudioABtn">
          <div class="stub-block" v-if="showSyncBtn"></div>

          <button class="action-btns"
                  @click="doActionAudioA(null)">
            <svg class="action-btn" v-show="!isPlaying1 && !syncMode" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Media / Play">
                <path id="Vector" d="M5 17.3336V6.66698C5 5.78742 5 5.34715 5.18509 5.08691C5.34664 4.85977 5.59564 4.71064 5.87207 4.67499C6.18868 4.63415 6.57701 4.84126 7.35254 5.25487L17.3525 10.5882L17.3562 10.5898C18.2132 11.0469 18.642 11.2756 18.7826 11.5803C18.9053 11.8462 18.9053 12.1531 18.7826 12.4189C18.6418 12.7241 18.212 12.9537 17.3525 13.4121L7.35254 18.7454C6.57645 19.1593 6.1888 19.3657 5.87207 19.3248C5.59564 19.2891 5.34664 19.1401 5.18509 18.9129C5 18.6527 5 18.2132 5 17.3336Z"
                      stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>

            <svg class="action-btn" v-show="isPlaying1 && !syncMode" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Media / Pause">
                <g id="Vector">
                  <path d="M15 5.5V18.5C15 18.9647 15 19.197 15.0384 19.3902C15.1962 20.1836 15.816 20.8041 16.6094 20.9619C16.8026 21.0003 17.0349 21.0003 17.4996 21.0003C17.9642 21.0003 18.1974 21.0003 18.3906 20.9619C19.184 20.8041 19.8041 20.1836 19.9619 19.3902C20 19.1987 20 18.9687 20 18.5122V5.48777C20 5.03125 20 4.80087 19.9619 4.60938C19.8041 3.81599 19.1836 3.19624 18.3902 3.03843C18.197 3 17.9647 3 17.5 3C17.0353 3 16.8026 3 16.6094 3.03843C15.816 3.19624 15.1962 3.81599 15.0384 4.60938C15 4.80257 15 5.03534 15 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4 5.5V18.5C4 18.9647 4 19.197 4.03843 19.3902C4.19624 20.1836 4.81599 20.8041 5.60938 20.9619C5.80257 21.0003 6.0349 21.0003 6.49956 21.0003C6.96421 21.0003 7.19743 21.0003 7.39062 20.9619C8.18401 20.8041 8.8041 20.1836 8.96191 19.3902C9 19.1987 9 18.9687 9 18.5122V5.48777C9 5.03125 9 4.80087 8.96191 4.60938C8.8041 3.81599 8.18356 3.19624 7.39018 3.03843C7.19698 3 6.96465 3 6.5 3C6.03535 3 5.80257 3 5.60938 3.03843C4.81599 3.19624 4.19624 3.81599 4.03843 4.60938C4 4.80257 4 5.03534 4 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </g>
            </svg>
          </button>

          <div class="audio-progress-time">{{ formatTime(audio1.current) }} / {{ formatTime(audio1.duration) }}</div>

          <div class="audio-progress-wrapper"
               :style="{ background: $props.waveColor }"
               @mousedown="startDrag('slider1', $event)"
               @touchstart="startDrag('slider1', $event)"
               ref="slider1">
            <div class="slider-thumb" :style="{ 'background-color': $props.mainColor, left: slider1.thumbPosition + '%' }"></div>
            <div class="audio-progress" :style="{ width: `${getACurrentPercent}%`, background: $props.mainColor }"></div>
          </div>
        </div>

        <div class="audio-block audio-block-shift" v-if="showAudioBBtn">
          <div class="stub-block" v-if="showSyncBtn"></div>

          <button class="action-btns"
                  @click="doActionAudioB(null)">
            <svg class="action-btn" v-show="!isPlaying2 && !syncMode" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Media / Play">
                <path id="Vector" d="M5 17.3336V6.66698C5 5.78742 5 5.34715 5.18509 5.08691C5.34664 4.85977 5.59564 4.71064 5.87207 4.67499C6.18868 4.63415 6.57701 4.84126 7.35254 5.25487L17.3525 10.5882L17.3562 10.5898C18.2132 11.0469 18.642 11.2756 18.7826 11.5803C18.9053 11.8462 18.9053 12.1531 18.7826 12.4189C18.6418 12.7241 18.212 12.9537 17.3525 13.4121L7.35254 18.7454C6.57645 19.1593 6.1888 19.3657 5.87207 19.3248C5.59564 19.2891 5.34664 19.1401 5.18509 18.9129C5 18.6527 5 18.2132 5 17.3336Z"
                      stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>

            <svg class="action-btn" v-show="isPlaying2 && !syncMode" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Media / Pause">
                <g id="Vector">
                  <path d="M15 5.5V18.5C15 18.9647 15 19.197 15.0384 19.3902C15.1962 20.1836 15.816 20.8041 16.6094 20.9619C16.8026 21.0003 17.0349 21.0003 17.4996 21.0003C17.9642 21.0003 18.1974 21.0003 18.3906 20.9619C19.184 20.8041 19.8041 20.1836 19.9619 19.3902C20 19.1987 20 18.9687 20 18.5122V5.48777C20 5.03125 20 4.80087 19.9619 4.60938C19.8041 3.81599 19.1836 3.19624 18.3902 3.03843C18.197 3 17.9647 3 17.5 3C17.0353 3 16.8026 3 16.6094 3.03843C15.816 3.19624 15.1962 3.81599 15.0384 4.60938C15 4.80257 15 5.03534 15 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4 5.5V18.5C4 18.9647 4 19.197 4.03843 19.3902C4.19624 20.1836 4.81599 20.8041 5.60938 20.9619C5.80257 21.0003 6.0349 21.0003 6.49956 21.0003C6.96421 21.0003 7.19743 21.0003 7.39062 20.9619C8.18401 20.8041 8.8041 20.1836 8.96191 19.3902C9 19.1987 9 18.9687 9 18.5122V5.48777C9 5.03125 9 4.80087 8.96191 4.60938C8.8041 3.81599 8.18356 3.19624 7.39018 3.03843C7.19698 3 6.96465 3 6.5 3C6.03535 3 5.80257 3 5.60938 3.03843C4.81599 3.19624 4.19624 3.81599 4.03843 4.60938C4 4.80257 4 5.03534 4 5.5Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </g>
            </svg>
          </button>

          <div class="audio-progress-time">{{ formatTime(audio2.current) }} / {{ formatTime(audio2.duration) }}</div>

          <div class="audio-progress-wrapper"
               :style="{ background: $props.waveColor }"
               @mousedown="startDrag('slider2', $event)"
               @touchstart="startDrag('slider2', $event)"
               ref="slider2">
            <div class="slider-thumb" :style="{ 'background-color': $props.mainColor, left: slider2.thumbPosition + '%' }"></div>
            <div class="audio-progress" :style="{ width: `${getBCurrentPercent}%`, background: $props.mainColor }"></div>
          </div>
        </div>

      </div>
    </div>

    <div class="dual-track-player-block" v-if="$props.showTranscription && (transcriptionA.text || transcriptionB.text)">
      <div class="default-title">Transcript</div>
      <div class="transcriptions">
        <div class="transcription">
          <template v-if="transcriptionA || transcriptionB">
            <div class="caller"
                 :class="[!isPlaying1 && isPlaying2 && 'block-block']">
              <span>Caller</span>
              <div v-if="transcriptionA.text">
                <div v-if="transcriptionA.phoneNumber">
                  {{ transcriptionA.phoneNumber }}
                </div>
                <div v-if="transcriptionA.date" class="date">
                  {{ formatDate(transcriptionA.date) }}
                </div>
                <div class="text">
                  {{ transcriptionA.text }}
                </div>
              </div>
              <div v-else>
                No Data
              </div>
            </div>
            <div class="callee"
                 :class="[!isPlaying2 && isPlaying1 && 'block-block']">
              <span>Callee</span>
              <div v-if="transcriptionB.text">
                <div v-if="transcriptionB.phoneNumber">
                  {{ transcriptionB.phoneNumber }}
                </div>
                <div v-if="transcriptionB.date" class="date">
                  {{ formatDate(transcriptionB.date) }}
                </div>
                <div class="text">
                  {{ transcriptionB.text }}
                </div>
              </div>
              <div v-else>
                No Data
              </div>
            </div>
          </template>
          <div v-else>
            No Data
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import { loadAndTransformAudio } from './audioNormalization'

export default {
  props: {
    audioA: {
      type: String
    },
    audioB: {
      type: String
    },
    showTranscription: {
      type: Boolean,
      default: true
    },
    transcriptions: {
      type: [Object, Array],
      default: () => ({})
    },
    totalTime: {
      type: [Number, String]
    },
    mainColor: {
      type: String,
      default: '#4563ca'
    },
    waveColor: {
      type: String,
      default: '#b4bfe8'
    },
    interval: {
      type: [Number, String],
      default: 7
    }
  },
  data () {
    return {
      wavesurfer1: null,
      wavesurfer2: null,
      syncMode: false,
      isPlaying1: false,
      isPlaying2: false,
      isAudio1loading: false,
      isAudio2loading: false,

      audio1: {
        current: 0,
        duration: 0
      },
      audio2: {
        current: 0,
        duration: 0
      },

      isDragging: false,
      slider1: {
        thumbPosition: 0,
        value: 0
      },
      slider2: {
        thumbPosition: 0,
        value: 0
      }
    }
  },
  mounted () {
    window.addEventListener('mousemove', this.onDrag)
    window.addEventListener('mouseup', this.stopDrag)
    window.addEventListener('touchmove', this.onDrag)
    window.addEventListener('touchend', this.stopDrag)

    this.initWavesurfers()
  },
  beforeDestroy () {
    if (this.wavesurfer1) {
      this.wavesurfer1.destroy()
    }

    if (this.wavesurfer2) {
      this.wavesurfer2.destroy()
    }

    window.removeEventListener('mousemove', this.onDrag)
    window.removeEventListener('mouseup', this.stopDrag)
    window.removeEventListener('touchmove', this.onDrag)
    window.removeEventListener('touchend', this.stopDrag)
  },
  computed: {
    isAudioA () {
      return !!this.$props.audioA
    },
    isAudioB () {
      return !!this.$props.audioB
    },
    isCurrentTimeSame () {
      return this.processNumber(this.audio1.current, 1) === this.processNumber(this.audio2.current, 1)
    },
    isLoading () {
      return this.isAudio1loading || this.isAudio2loading
    },
    showAudioABtn () {
      return this.$props.audioA && this.audio1.duration > 0
    },
    showAudioBBtn () {
      return this.$props.audioB && this.audio2.duration > 0
    },
    showSyncBtn () {
      return this.showAudioABtn && this.showAudioBBtn
    },
    getACurrentPercent () {
      return (this.audio1.current / this.audio1.duration) * 100
    },
    getBCurrentPercent () {
      return (this.audio2.current / this.audio2.duration) * 100
    },
    _transcriptions () {
      return this.$props.transcriptions
    },
    transcriptionA () {
      const t = this._transcriptions
      const _t = Array.isArray(t) ? t : Object.values(t)

      let a = []

      _t.forEach(el => {
        if (Array.isArray(el)) {
          a.push(...el)
        } else {
          a.push(el)
        }
      })

      a = a.filter(el => el.type === 'called')

      return a.length ? {
        date: a[0]?.date,
        text: a.map(el => el.text).join(' ')?.trim(),
        phoneNumber: a[0]?.phoneNumber
      } : null
    },
    transcriptionB () {
      const t = this._transcriptions
      const _t = Array.isArray(t) ? t : Object.values(t)

      let b = []

      _t.forEach(el => {
        if (Array.isArray(el)) {
          b.push(...el)
        } else {
          b.push(el)
        }
      })

      b = b.filter(el => el.type === 'caller')

      return b.length ? {
        date: b[0].date,
        text: b.map(el => el.text).join(' ')?.trim(),
        phoneNumber: b[0].phoneNumber
      } : null
    }
  },
  methods: {
    formatDate (date) {
      return date.toLocaleString('en-US', {
        month: 'short', // "Aug"
        day: '2-digit', // "25"
        year: 'numeric', // "2024"
        hour: '2-digit', // "14"
        minute: '2-digit', // "17"
        hour12: false // Use 24-hour format
      }).replace(',', '') // Remove the comma after the day
    },
    startDrag (slider, event) {
      this.isDragging = slider
      this.onDrag(event)
    },
    stopDrag () {
      this.isDragging = false
    },
    onDrag (event) {
      if (!this.isDragging) return

      const slider = this.$refs[this.isDragging]
      const rect = slider.getBoundingClientRect()
      const clientX = event.touches ? event.touches[0].clientX : event.clientX
      const position = Math.min(Math.max(clientX - rect.left, 0), rect.width)

      const thumbPosition = (position / rect.width) * 100
      this[this.isDragging].thumbPosition = thumbPosition

      if (this.syncMode) {
        this.updateAudioATime(this.audio1.duration * (thumbPosition / 100))
        this.updateAudioBTime(this.audio2.duration * (thumbPosition / 100))

        const anotherSlider = this.isDragging === 'slider1' ? 'slider2' : 'slider1'

        const v = this.isDragging === 'slider1' ? this.audio2.current : this.audio1.current

        this.updateThumbPositionFromValue(anotherSlider, v)

        return
      }

      if (this.isDragging === 'slider1') {
        this.updateAudioATime(this.audio1.duration * (thumbPosition / 100))
      } else {
        this.updateAudioBTime(this.audio2.duration * (thumbPosition / 100))
      }
    },
    updateThumbPositionFromValue (slider, value) {
      const min = 0
      const max = this[slider === 'slider1' ? 'audio1' : 'audio2'].duration

      this[slider].thumbPosition = ((value - min) / (max - min)) * 100
    },

    cutToThreeDecimals (num, count = 4) {
      return parseFloat(num.toFixed(count))
    },
    processNumber (num, count = 4) {
      if ([null, undefined, ''].includes(num)) return
      const strNum = num.toString()
      if (strNum.includes('.') && strNum.split('.')[1].length > count) {
        return this.cutToThreeDecimals(num, count)
      }
      return num
    },
    formatTime (seconds) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = Math.floor(seconds % 60)

      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
      const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds

      return `${formattedMinutes}:${formattedSeconds}`
    },
    async initWavesurfers () {
      this.isAudio1loading = true
      this.isAudio2loading = true

      if (this.$props.audioA || this.$props.audioB) {
        this.$emit('loading', this.isLoading)
      }

      const audioBlobs = await loadAndTransformAudio(this.$props.audioA, this.$props.audioB)

      const a = audioBlobs && audioBlobs?.blobA
      const b = audioBlobs && audioBlobs?.blobB

      const baseWavesurferOptions = {
        height: 80,
        waveColor: this.$props.waveColor,
        progressColor: this.$props.mainColor,
        cursorColor: this.$props.mainColor,
        cursorWidth: 3,
        dragToSeek: undefined,
        responsive: true,
        interact: false,
        barWidth: 3,
        barHeight: 80, // the height of the wave
        barGap: 5 // the optional spacing between bars of the wave, if not provided will be calculated in legacy format
      }

      if (this.$props.audioA) {
        this.wavesurfer1 = WaveSurfer.create({
          // plugins: [topTimeline],
          container: '#waveform',
          normalize: true,
          ...baseWavesurferOptions
        })

        this.wavesurfer1.on('play', () => {
          this.isPlaying1 = true
          this.$emit('playing1', this.isPlaying1)
        })
        this.wavesurfer1.on('pause', () => {
          this.isPlaying1 = false
          this.$emit('playing1', this.isPlaying1)
        })

        this.wavesurfer1.on('audioprocess', (v) => {
          this.audio1.current = v

          if (!this.isDragging) {
            this.updateThumbPositionFromValue('slider1', v)
          }

          // if (this.syncMode && this.audio2.duration) {
          //   this.updateAudioATime(v)
          // }
        })

        this.wavesurfer1.on('finish', () => {
          if (this.syncMode) {
            this.syncMode = false
          }
        })

        this.wavesurfer1.on('ready', () => {
          this.audio1.duration = this.wavesurfer1.getDuration()
          this.isAudio1loading = false
          this.doActionAudioA('reset')
          this.$emit('loading', this.isLoading)
          // console.log(this.audio1.duration)
        })
      }

      if (this.$props.audioB) {
        this.wavesurfer2 = WaveSurfer.create({
          plugins: [TimelinePlugin.create()],
          container: '#waveform2',
          ...baseWavesurferOptions
        })

        this.wavesurfer2.on('play', () => {
          this.isPlaying2 = true
          this.$emit('playing2', this.isPlaying2)
        })
        this.wavesurfer2.on('pause', () => {
          this.isPlaying2 = false
          this.$emit('playing2', this.isPlaying2)
        })

        this.wavesurfer2.on('audioprocess', (v) => {
          this.audio2.current = v

          if (!this.isDragging) {
            this.updateThumbPositionFromValue('slider2', v)
          }

          // if (this.syncMode && this.audio1.duration) {
          //   this.updateAudioATime(v)
          // }
        })

        this.wavesurfer2.on('finish', () => {
          if (this.syncMode) {
            this.syncMode = false
          }
        })

        this.wavesurfer2.on('ready', () => {
          this.audio2.duration = this.wavesurfer2.getDuration()
          this.isAudio2loading = false
          this.doActionAudioB('reset')
          this.$emit('loading', this.isLoading)
          // console.log(this.audio2.duration)
        })
      }

      // Load audio files
      if (this.isAudioA) {
        try {
          this.isAudio1loading = true
          this.$emit('loading', this.isLoading)
          this.wavesurfer1[a ? 'loadBlob' : 'load'](a || this.audioA)
        } catch (e) {
          console.log(e)
          this.isAudio1loading = false
          this.$emit('loading', this.isLoading)
        }
      } else {
        this.isAudio1loading = false
      }

      if (this.isAudioB) {
        try {
          this.isAudio2loading = true
          this.$emit('loading', this.isLoading)
          this.wavesurfer2[b ? 'loadBlob' : 'load'](b || this.audioB)
        } catch (e) {
          console.log(e)
          this.isAudio2loading = false
          this.$emit('loading', this.isLoading)
        }
      } else {
        this.isAudio2loading = false
      }
    },
    playAudioA () {
      if (this.wavesurfer1) {
        this.wavesurfer1.play()
      }
    },
    playAudioB () {
      if (this.wavesurfer2) {
        this.wavesurfer2.play()
      }
    },
    pauseAudioA () {
      if (this.wavesurfer1) {
        this.wavesurfer1.pause()
      }
    },
    pauseAudioB () {
      if (this.wavesurfer2) {
        this.wavesurfer2.pause()
      }
    },
    updateAudioATime (time = 0) {
      this.audio1.current = time
      this.wavesurfer1.setTime(time)
    },
    updateAudioBTime (time = 0) {
      this.audio2.current = time
      this.wavesurfer2.setTime(time)
    },
    resetAudioA () {
      if (this.wavesurfer1) {
        this.wavesurfer1.pause()
        this.updateAudioATime(0)
        this.updateAudioATime(0)
      }
    },
    resetAudioB () {
      if (this.wavesurfer2) {
        this.wavesurfer2.pause()
        this.updateAudioBTime(0)
        this.updateAudioBTime(0)
      }
    },
    doActionAudioA (action = null) {
      if (action) {
        switch (action) {
          case 'reset':
            this.resetAudioA()
            break
          case 'play':
            this.playAudioA()
            break
          case 'pause':
            this.pauseAudioA()
            break
        }
        return
      }

      this.syncMode = false

      if (this.isPlaying1) {
        this.pauseAudioA()
      } else {
        this.pauseAudioB()
        this.playAudioA()
      }
    },
    doActionAudioB (action = null) {
      if (action) {
        switch (action) {
          case 'reset':
            this.resetAudioB()
            break
          case 'play':
            this.playAudioB()
            break
          case 'pause':
            this.pauseAudioB()
            break
        }
        return
      }

      this.syncMode = false

      if (this.isPlaying2) {
        this.pauseAudioB()
      } else {
        this.pauseAudioA()
        this.playAudioB()
      }
    },
    async toggleSync () {
      this.syncMode = true

      if ((!this.isPlaying1 && this.isPlaying2) || (this.isPlaying1 && !this.isPlaying2)) {
        this.doActionAudioA('reset')
        this.doActionAudioB('reset')

        await new Promise(resolve => setTimeout(resolve, 500))

        this.wavesurfer1.play()
        this.wavesurfer2.play()
      } else {
        if (this.isPlaying1 && this.isPlaying2) {
          this.wavesurfer1.pause()
          this.wavesurfer2.pause()
          this.syncMode = false
        } else {
          this.wavesurfer1.play()
          this.wavesurfer2.play()
        }
      }
    }
  }
}
</script>

<style scoped>
.default-title {
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: #152043;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.dual-track-player {
  background: #f6f7f9;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.dual-track-player-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  background: white;
  box-shadow: 0px 1px 1px rgba(109, 121, 162, 0.09), 0px 0px 1px rgba(109, 121, 162, 0.1), 0px 0px 0px rgba(109, 121, 162, 0.1);
  padding: 20px;
}

.waveform-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  border-top: 1px solid rgba(128, 128, 128, 0.3);
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  padding: 2px;
  margin: 20px 0 20px 0;
}

.waveform-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waveform {
  width: 100%;
}

.play-controls {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.play-controls button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 10px;
}

.transcriptions {
  margin-top: 20px;
  width: 100%;
}

.transcription {
  display: flex;
  flex-direction: column;
}

.caller,
.callee {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.caller span,
.callee span {
  background-color: gray;
  color: white;
  padding: 20px 10px;
  border-radius: 100%;
  margin-right: 10px;
}

p {
  margin: 0;
}

.audio-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  margin-top: 20px;
}
.audio-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.audio-progress-wrapper {
  width: 100%;
  height: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 6px;
}
.audio-progress-time {
  white-space: nowrap;
  width: 130px;
}
.audio-progress {
  width: 100%;
  height: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
}
.action-btns {
  height: 24px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.stub-block {
  width: 30px;
  min-width: 24px;
  min-height: 24px;
  background-color: transparent;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 10px solid transparent;
  border-bottom: 0;
}
.half-circle {
  z-index: 1;
  width: 62px;
  height: 32px;
  background-color: transparent;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 10px solid gray;
  border-bottom: 0;
  transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -moz-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  -o-transform: rotate(-90deg) translate(-50%, 50%);
  transform: rotate(-90deg) translate(-50%, 50%);
}
.half-circle:hover {
  z-index: 1;
  width: 62px;
  height: 32px;
  background-color: transparent;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 10px solid #494949;
  border-bottom: 0;
  transform-origin: 0 50%;
  transition: all 0.2s ease;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -moz-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  -o-transform: rotate(-90deg) translate(-50%, 50%);
  transform: rotate(-90deg) translate(-50%, 50%);
}

.action-btn {
  stroke: gray;
}
.action-btn:hover {
  stroke: #494949 !important;
  transition: all 0.2s ease;
}

.audio-block-shift {
  padding-left: 0px;
}

.slider-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  touch-action: none; /* Prevent default scrolling behavior */
}

.slider-thumb:hover {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  touch-action: none; /* Prevent default scrolling behavior */
}

.slider-thumb:active {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  touch-action: none; /* Prevent default scrolling behavior */
}
.date {
  font-size: 12px;
  color: #bbc0d1;
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #152043;
}
.block-block {
  opacity: 0.5;
}
</style>
