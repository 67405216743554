<template>
  <div class="main pt-4">
    <h1 class="fz-28 dark-text font-weight-semi-bold mb-5 px-4 px-md-0">
      Recordings
    </h1>
    <v-card class="white elevation-1">
      <v-data-table
        :headers="headers"
        :items="recordingList"
        :loading="loading"
        :items-per-page="itemsPerPage"
        hide-default-footer
        disable-sort
      >
        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.duration`]="{ item }">{{ item.duration }}s</template>
        <template v-slot:[`item.action`]="{ item }">
          <div v-if="item.a_leg_asr_url || item.b_leg_asr_url || item.a_leg_record_url || item.b_leg_record_url">
            <v-tooltip top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  height="36px"
                  v-bind="attrs"
                  v-on="on"
                  @click="viewRecording(item)"
                >
                  <v-icon color="#919EAB">preview</v-icon>
                </v-btn>
              </template>
              <span>View Recording</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <div v-if="total > itemsPerPage" class="d-flex justify-space-between">
        <div class="ma-5 br-3 grey-button d-flex flex-wrap h-max">
          <template v-for="index in countPage">
            <v-divider
              v-if="index"
              :key="index + 'v-divider--page'"
              vertical
              class="pa-0"
            />
            <v-btn
              :class="{'grey-button--selected': index === page}"
              text
              tile
              min-width="56"
              class=""
              :key="index"
              @click="pagination(index)"
            >{{ index }}
            </v-btn>
          </template>
        </div>

        <div class="ma-5 br-3 grey-button d-flex" style="min-width: 73px;">
          <v-btn
            text
            tile
            class="px-0"
            min-width="36"
            width="36px"
            @click="previousPage(page)"
          >
            <v-icon size="20" color="#687885">
              arrow_back
            </v-icon>
          </v-btn>
          <v-divider vertical/>
          <v-btn
            text
            tile
            min-width="36"
            class="light-text px-0"
            width="36px"
            @click="nextPage(page)"
          >
            <v-icon size="20" color="#687885">
              arrow_forward
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-dialog
      v-model="viewRecordingDialog"
      persistent
    >
      <v-card :loading="dialogLoading || loadingAudio" style="background: #f4f5f7; padding: 12px;">
        <Transcriber :audio-a="recording.a_leg_record_url"
                     :audio-b="recording.b_leg_record_url"
                     :transcriptions="[
                         { text: a_leg_asr_url, type: 'called' },
                         { text: b_leg_asr_url, type: 'caller' },
                     ]"
                     v-if="viewRecordingDialog"
                     @loading="loadingAudio = $event">
          <template #title-append>
            <v-tooltip top content-class="v-tooltip--top" v-if="recording.a_leg_record_url || recording.b_leg_record_url">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="downloadFiles"
                    :loading="loadingDownloadZipFile"
                    v-bind="attrs"
                    v-on="on"
                    icon
                >
                  <v-icon size="22px" color="#919EAB">download</v-icon>
                </v-btn>
              </template>
              <span>Download Audio</span>
            </v-tooltip>
          </template>
        </Transcriber>

<!--        <div class="v-card-body">-->
<!--          <div class="v-card-body-block" v-if="recording.a_leg_record_url">-->
<!--            <div class="v-card-body-block-title">Caller</div>-->
<!--            <audio-player-->
<!--                :src="recording.a_leg_record_url"-->
<!--                :disable-play="dialogLoading || playingBoth"-->
<!--                :disable-both="dialogLoading || (!playingBoth && (playingA || playingB))"-->
<!--                allow-download-->
<!--                ref="playerA"-->
<!--                name="A record"-->
<!--                class="mx-6"-->
<!--                @play="playingA = true"-->
<!--                @toggleBoth="toggleBoth"-->
<!--                @ended="ended($event, 'playingA')"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="v-card-body-block mt-4" v-if="recording.b_leg_record_url">-->
<!--            <div class="v-card-body-block-title">Callee</div>-->
<!--            <audio-player-->
<!--                :src="recording.b_leg_record_url"-->
<!--                :disable-play="dialogLoading || playingBoth"-->
<!--                :disable-both="dialogLoading || (!playingBoth && (playingA || playingB))"-->
<!--                allow-download-->
<!--                ref="playerB"-->
<!--                name="B record"-->
<!--                class="mx-6"-->
<!--                @play="playingB = true"-->
<!--                @toggleBoth="toggleBoth"-->
<!--                @ended="ended($event, 'playingB')"-->
<!--            />-->
<!--          </div>-->
<!--          <p v-if="!recording.a_leg_record_url && !recording.b_leg_record_url" class="mx-6 px-4">No Data</p>-->
<!--        </div>-->

<!--        <v-divider class="mt-6"/>-->

<!--        <v-card-title class="font-weight-bold">-->
<!--          Transcript-->
<!--        </v-card-title>-->

<!--        <div class="transcript-wrapper">-->
<!--          <template v-if="a_leg_asr_url || b_leg_asr_url">-->
<!--            <div class="px-4" v-if="!dialogLoading && (playingA || (!playingA && !playingB))">-->
<!--              <p class="font-weight-bold">Caller</p>-->
<!--              <p>{{ a_leg_asr_url || 'No Data' }}</p>-->
<!--            </div>-->
<!--            <div class="px-4" v-if="!dialogLoading && (playingB || (!playingA && !playingB))">-->
<!--              <p class="font-weight-bold">Callee</p>-->
<!--              <p>{{ b_leg_asr_url || 'No Data' }}</p>-->
<!--            </div>-->
<!--          </template>-->
<!--          <p v-else class="px-4">No Data</p>-->
<!--        </div>-->

        <v-card-actions class="pb-5 pt-3 justify-center">
          <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'" class="mx-2">
            <v-btn
              color="primary"
              outlined
              class="text-capitalize w-100"
              @click="closeRecordingDialog"
            >
              Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import Transcriber from '../components/Transcriber/Transcriber.vue'
import { createZipWithMergedAudio } from '../components/Transcriber/downloadAudioAndMerged'

export default {
  name: 'Recordings',
  components: { Transcriber },
  data: () => ({
    headers: [
      {
        text: 'Recording UUID',
        value: 'uuid',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Time',
        align: 'start',
        value: 'created',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Caller ID',
        value: 'caller',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Destination',
        value: 'destination',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Duration',
        value: 'duration',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    loading: false,
    dialogLoading: false,
    loadingAudio: false,
    loadingDownloadZipFile: false,
    page: 1,
    itemsPerPage: 10,
    recordingList: [],
    total: 0,
    viewRecordingDialog: false,
    recording: {},
    a_leg_asr_url: '',
    b_leg_asr_url: '',
    playingA: false,
    playingB: false,
    playingBoth: false
  }),
  computed: {
    countPage () {
      return Math.ceil(this.total / this.itemsPerPage)
    }
  },
  created () {
    this.loadRecordings()
  },
  watch: {
    page () {
      this.loadRecordings()
    }
  },
  methods: {
    ...mapActions(['getLogCalls']),
    async downloadFiles () {
      try {
        this.loadingDownloadZipFile = true

        await createZipWithMergedAudio({
          zipNamePostfix: this.recording.uuid,
          urlAudioA: this.recording?.a_leg_record_url,
          urlAudioB: this.recording?.b_leg_record_url
        })
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loadingDownloadZipFile = false
      }
    },
    async loadRecordings () {
      try {
        this.loading = true
        const params = {
          skip: (this.page - 1) * this.itemsPerPage,
          take: this.itemsPerPage
        }
        const { data } = await this.getLogCalls(params)
        this.recordingList = data.payload.items.map(item => ({
          ...item,
          caller: item.direction === 'in' ? item.ani : item.dnis,
          destination: item.direction === 'in' ? item.dnis : item.ani
        }))
        this.total = data.payload.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async viewRecording (recording) {
      this.recording = recording
      this.viewRecordingDialog = true
      this.dialogLoading = true
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
      if (recording.a_leg_asr_url) {
        await fetch(recording.a_leg_asr_url, { headers })
          .then(r => r.json())
          .then(json => {
            this.a_leg_asr_url = json?.a_live?.txt || ''
            this.b_leg_asr_url = json?.b_live?.txt || ''
          })
          .catch(err => {
            throw new Error(err)
          })
      }
      /* if (recording.b_leg_asr_url) {
        await fetch(recording.b_leg_asr_url, { headers })
          .then(r => r.json())
          .then(json => {
            this.b_leg_asr_url = json?.b_live?.txt || ''
          })
          .catch(err => {
            throw new Error(err)
          })
      } */
      this.dialogLoading = false
    },
    closeRecordingDialog () {
      if (this.$refs.playerA) this.$refs.playerA.ended()
      if (this.$refs.playerB) this.$refs.playerB.ended()
      this.viewRecordingDialog = false
      this.recording = {}
      this.a_leg_asr_url = ''
      this.b_leg_asr_url = ''
    },
    toggleBoth () {
      this.playingBoth = !this.playingBoth
      if (this.$refs.playerA) this.playingBoth ? this.$refs.playerA.play() : this.$refs.playerA.ended()
      if (this.$refs.playerB) this.playingBoth ? this.$refs.playerB.play() : this.$refs.playerB.ended()
    },
    ended (disableBoth, playing) {
      this.disableBoth = !disableBoth
      this[playing] = false
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  width: 100% !important;
  max-width: 1200px!important;
  min-width: min(600px, 90%);
  overflow-x: hidden;
}
::v-deep .v-card {
  overflow-x: hidden;
}
.v-card-body {
  overflow: hidden;
}
.transcript-wrapper {
  overflow-x: auto !important;
  margin-inline: 20px;
}
.v-card-body-block-title {
  padding-left: 25px;
  font-weight: 600;
}
.v-card-body-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0 10px 0;
  cursor: pointer;
}
</style>
